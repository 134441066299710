import FrontPage from './pages/FrontPage';
import NavBar from './components/NavBar';
import UserProfile from './pages/UserProfile';
import './App.css';
import Login from './pages/login';
import { Routes, BrowserRouter, Route} from 'react-router-dom';

function App({ isLoggedIn }) {
  return (
    <BrowserRouter>
      
      <div className="App">
        <NavBar isLoggedIn={isLoggedIn}/>
        <Routes>
          <Route path="/" element={<FrontPage isLoggedIn={isLoggedIn}/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/user_profile" element={<UserProfile />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
